import React from "react";
import { Add } from "@material-ui/icons";
import { Button, TextField, MenuItem } from "@material-ui/core";

import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
/* eslint-disable */
import firebase from "../firebase";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

class GroupsPage extends React.Component {
  //history = useHistory();

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      groupsData: new Map(),
      isActive: false,
      isAuthenticating: false,
      fff: "sss",
      kid: "",
      fullName: "",
      kidsData: new Map(),
      columns: [],
    };
  }

  componentWillMount() {
    const cols = [
      { field: "id", headerName: "ID", width: 90 },
      {
        field: "fullNames",
        headerName: "Full Names",
        width: 350,
      },
      {
        field: "location",
        headerName: "Location",
        width: 150,
      },
      {
        field: "date",
        headerName: "Date",
        width: 150,
      },
    ];
    this.setState({ columns: cols });

    var url_string = window.location.href;
    if (global.config.uid === "") {
      window.alert("You are not signed in");
      window.location.replace("/");
    } else {
      var there = this;
      const db = firebase.firestore();

      new Promise(function () {
        db.collection("Main")
          .doc(global.config.uid)
          .collection("Groups")
          .orderBy("Date", "desc")
          .onSnapshot((snapshot) => {
            there.setState({
              groupsData: snapshot.docs.map((doc) => ({
                id: doc.id,
                fullNames: doc.data().fullNames,
                date: doc.data().Date.toDate().toDateString(),
                kid: doc.data().Kids,
                location: doc.data().Location,
                data: doc.data(),
              })),
            });
          });
      });
    }
  }
  componentDidMount() {
    console.log(this.state.groupsData);
  }

  openSingle = (parameters) => {
    this.props.history.push("/group-profile?groupID=" + parameters.id);
  };

  openNewGroupSession = () => {
    this.props.history.push("/group-profile");
  };

  render() {
    if (this.state.isAuthenticating) return null;

    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text="Loading your content..."
      >
        <div>
          <div className="page-content" id="singles-page">
            <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
              <div className="container container-fluid text-center">
                <div>
                  <div>
                    <Button
                      id="add-user"
                      onClick={this.openNewGroupSession}
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      style={{
                        float: "right",
                        marginBottom: "15px",
                        marginLeft: "10px",
                      }}
                      endIcon={<Add />}
                    >
                      Add Group Session
                    </Button>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    ".MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "bold !important",
                      overflow: "visible !important",
                      fontSize: "Larger",
                    },
                    ".MuiDataGrid-virtualScroller": {
                      overflow: "visible !important",
                      fontSize: "Large",
                    },
                  }}
                >
                  <DataGrid
                    getRowHeight={() => "auto"}
                    onCellClick={this.openSingle}
                    rows={this.state.groupsData}
                    columns={this.state.columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 15,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
export default withRouter(GroupsPage);
