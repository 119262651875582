import React from "react";
import { Add } from "@material-ui/icons";
import {
  Button,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
/* eslint-disable */
import firebase from "../firebase";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CalculatorForm from "./CalculatorForm";

class Calculator extends React.Component {
  //history = useHistory();

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    var url_string = window.location.href;
    if (global.config.uid === "") {
      window.alert("You are not signed in");
      window.location.replace("/");
    } else {
    }
  }
  componentDidMount() {}

  render() {
    if (this.state.isAuthenticating) return null;

    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text="Loading your content..."
      >
        <div className="page-content" id="Calculator-page">
          <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
            <div className="container container-fluid text-center">
              <CalculatorForm />
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
export default withRouter(Calculator);
