import React, { Fragment, Component } from "react";
import {Route, Switch, Redirect, BrowserRouter as Router} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css"

// Components
import SinglesNavigation from "./Components/Navigation/SinglesNavigation";
import ScrollTop from "./Components/ScrollTop"
import Application from "./Components/Application";

// Pages
import MainPage from "./Pages/MainPage"

import Calculator from "./Pages/Calculator"
import KidsPage from "./Pages/KidsPage"
import KidsProfile from "./Pages/KidsProfile"
import NotFoundPage from "./Pages/404"
import UserProvider from "./providers/UserProvider"
import SessionsPage from "./Pages/SessionsPage";
import GroupsPage from "./Pages/GroupsPage";
import SessionsProfile from "./Pages/SessionsProfile";
import LocationsProfile from "./Pages/LocationsProfile";
import LocationsPage from "./Pages/LocationsPage";
import GroupSessionsForm from "./Components/GroupSessionsForm";

const Home = () =>  (
  <Fragment>
    
    <MainPage />
  </Fragment>
);

const Singles = () =>  (
  <Fragment>
    
    <KidsPage />
  </Fragment>
);
const Sessions = () =>  (
  <Fragment>
    
    <SessionsPage />
  </Fragment>
);
const Groups = () =>  (
  <Fragment>
    
    <GroupsPage />
  </Fragment>
);

const Locations = () =>  (
  <Fragment>
    
    <LocationsPage />
  </Fragment>
);


const CalculatorPage= () =>  (
  <Fragment>
    
    <Calculator />
  </Fragment>
);

const KidsApplication = () =>  (
  <Fragment>
    
    <KidsProfile />
  </Fragment>
);

const LocationsApplication = () =>  (
  <Fragment>
    
    <LocationsProfile />
  </Fragment>
);


const SessionsApplication = () =>  (
  <Fragment>
    
    <SessionsProfile />
  </Fragment>
);


const GroupApplication = () =>  (
  <Fragment>
    
    <GroupSessionsForm />
  </Fragment>
);



const SignInPage = () =>  (
  <Fragment>
    <UserProvider>
      
      <Application />
    </UserProvider>
  </Fragment>
);


const NotFound = () =>  (
  <Fragment>
   
    <NotFoundPage />
  </Fragment>
);

class App extends Component {
  render() {
    return (
      <Router>  
        <SinglesNavigation/>
        <ScrollTop />
        <Switch>
          <Route exact path = "/" component={Home} />
          <Route exact path = "/kids" component={Singles} />
          <Route exact path = "/sessions" component={Sessions} />
          <Route exact path = "/groups" component={Groups} />
          <Route exact path = "/locations" component={Locations} />
          <Route exact path = "/calculator" component={CalculatorPage} />

          <Route exact path = "/kids-profile" component={KidsApplication} />
          <Route exact path = "/sessions-profile" component={SessionsApplication} />
          <Route exact path = "/group-profile" component={GroupApplication} />

          <Route exact path = "/locations-profile" component={LocationsApplication} />
          
          <Route exact path = "/sign-in" component={SignInPage} />
          <Route exact path="/404" component={NotFound} />
          <Redirect to="/404"/>
        </Switch>
      </Router>   
    );
  }
}

export default App;