import React from "react";
import { Add } from "@material-ui/icons";
import { Button, TextField, MenuItem } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
/* eslint-disable */
import firebase from "../firebase";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

class KidsPage extends React.Component {
  //history = useHistory();

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      kidsData: new Map(),
      isActive: true,
      isAuthenticating: true,
      fff: "sss",
      locationsData: new Map(),
      columns: [],
    };
  }

  componentWillMount() {
    const cols = [
      { field: "id", headerName: "ID", width: 90 },
      {
        field: "firstname",
        headerName: "First Name",
        width: 150,
      },
      {
        field: "lastname",
        headerName: "Last Name",
        width: 150,
      },
      {
        field: "location",
        headerName: "Location",
        width: 150,
      },
      {
        field: "age",
        headerName: "Age",
        width: 150,
      },
      {
        field: "eval",
        headerName: "Eval",
        width: "100%",
      },
    ];
    this.setState({ columns: cols });

    if (global.config.uid === "") {
      window.alert("You are not signed in");
      window.location.replace("/");
    } else {
      var there = this;
      const db = firebase.firestore();

      new Promise(function () {
        db.collection("Main")
          .doc(global.config.uid)
          .collection("Kids")
          .orderBy("LastName")
          .onSnapshot((snapshot) => {
            there.setState({
              kidsData: snapshot.docs.map((doc) => ({
                id: doc.id,
                age: doc.data().Age,
                eval: doc.data().Eval,
                firstname: doc.data().FirstName,
                lastname: doc.data().LastName,
                location: doc.data().Location,
                data: doc.data(),
              })),
            });

            db.collection("Main")
              .doc(global.config.uid)
              .collection("Locations")
              .onSnapshot((snapshot) => {
                debugger;

                there.setState({
                  locationsData: snapshot.docs.map((doc) => ({
                    id: doc.id,
                    Location: doc.data().Location,
                  })),
                });
                there.setState({ fff: "sdfr" });

                there.setState({ isAuthenticating: false });
                there.setState({ isActive: false });
                console.log(there.state.kidsData);
              });
          });
      });
    }
  }
  componentDidMount() {
    debugger;
    console.log(this.state.kidsData);
  }

  openSingle = (parameters) => {
    this.props.history.push("/kids-profile?kidID=" + parameters.id);
  };

  handleLocationNameChange = (event) => {
    const db = firebase.firestore();

    this.setState({ isAuthenticating: true });
    this.setState({ isActive: true });
    debugger;

    db.collection("Main")
      .doc(global.config.uid)
      .collection("Kids")
      .where("Location", "==", event.target.value)
      .onSnapshot((snapshot) => {
        this.setState({
          kidsData: snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
            age: doc.data().Age,
            eval: doc.data().Eval,
            firstname: doc.data().FirstName,
            lastname: doc.data().LastName,
            location: doc.data().Location,
            data: doc.data(),
          })),
        });

        this.setState({ isAuthenticating: false });
        this.setState({ isActive: false });
      });
  };

  openNew = () => {
    this.props.history.push("/kids-profile");
  };
  render() {
    if (this.state.isAuthenticating) return null;
    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text="Loading your content..."
      >
        <div>
          <div className="page-content" id="singles-page">
            <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
              <div className="container container-fluid text-center">
                <Button
                  id="add-user"
                  onClick={this.openNew}
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  style={{
                    float: "right",
                    marginBottom: "15px",
                    marginLeft: "10px",
                  }}
                  endIcon={<Add />}
                >
                  Add Kid
                </Button>

                <TextField
                  id="locationNames"
                  select
                  label="Select"
                  required
                  helperText="Please select a location"
                  variant="outlined"
                  value={this.state.kid}
                  style={{ marginTop: 0, width: "100%" }}
                  onChange={this.handleLocationNameChange}
                >
                  {this.state.locationsData.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.Location}
                      id={option.id}
                      name={option.Location}
                    >
                      {option.Location}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <table className="table table-hover table-striped" cursor="pointer">
                <thead>
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Age</th>

                    <th scope="col">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.kidsData.map(row=> (
                    <tr className='clickable-row' onClick={() => this.openSingle(row.id)} >
                      <td><a>{row.data.FirstName}</a></td>
                      <td> <a>{row.data.LastName}</a></td>
                      <td> <a>{row.data.Age}</a></td>
                      <td> <a>{row.data.Location}</a></td>
                    </tr>
                  ))}
                </tbody>
              </table> */}

                <br />
                <br />
                <br />
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    ".MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "bold !important",
                      overflow: "visible !important",
                      fontSize: "Larger",
                    },
                    ".MuiDataGrid-virtualScroller": {
                      overflow: "visible !important",
                      fontSize: "Large",
                    },
                  }}
                >
                  <DataGrid
                    onCellClick={this.openSingle}
                    rows={this.state.kidsData}
                    columns={this.state.columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 20,
                        },
                      },
                    }}
                    pageSizeOptions={[20]}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
export default withRouter(KidsPage);
