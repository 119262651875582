import React, { useState } from "react";
import { Link } from "@reach/router";
import { auth } from "../firebase";
import { TextField, Typography, InputLabel } from "@material-ui/core";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  console.log("test");

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      setError("Error signing in with password and email!");
      console.error("Error signing in with password and email", error);
    });
    // make check if accountCreated is false
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <div className="mt-8">
      <Typography
        variant="h2"
        display="block"
        align="center"
        id="profile-us"
        style={{
          paddingTop: "0",
          paddingBottom: "15px",
          margin: "0rem",
          fontSize: "2.5rem",
        }}
      >
        Sign In
      </Typography>
      <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
        {error !== null && (
          <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
            {error}
          </div>
        )}
        <form className="">
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Email Address:
              </InputLabel>
              <TextField
                type="email"
                id="userEmail"
                name="userEmail"
                variant="outlined"
                onChange={(event) => onChangeHandler(event)}
                placeholder="E.g: jdoe@gmail.com"
                value={email}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Password:</InputLabel>
              <TextField
                type="password"
                id="userPassword"
                variant="outlined"
                name="userPassword"
                placeholder="Your Password"
                onChange={(event) => onChangeHandler(event)}
                value={password}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn btn-success"
              style={{ margin: 0, width: "100%" }}
              onClick={(event) => {
                signInWithEmailAndPasswordHandler(event, email, password);
              }}
            >
              Sign in
            </button>
          </div>
        </form>

        <p className="text-center my-3">
          Don't have an account? <br />{" "}
          <Link
            to="passwordReset"
            className="text-blue-500 hover:text-blue-600"
          >
            Forgot Password?
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
