import React from "react";
import { Add } from "@material-ui/icons";
import { Button } from "@material-ui/core";

import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
/* eslint-disable */
import firebase from "../firebase";

class LocationsPage extends React.Component {
  //history = useHistory();

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      sessionsData: new Map(),
      isActive: true,
      isAuthenticating: true,
      fff: "sss",
      kid: "",
      fullName: "",
      kidsData: new Map(),
    };
  }

  componentWillMount() {
    debugger;
    console.log("ddd");

    var url_string = window.location.href;
    if (global.config.uid === "") {
      window.alert("You are not signed in");
      window.location.replace("/");
    } else {
      var there = this;
      const db = firebase.firestore();

      new Promise(function () {
        db.collection("Main")
          .doc(global.config.uid)
          .collection("Locations")
          .onSnapshot((snapshot) => {
            there.setState({
              sessionsData: snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
              })),
            });

            there.setState({ isAuthenticating: false });
            there.setState({ isActive: false });
          });
      });
    }
  }
  componentDidMount() {
    console.log(this.state.sessionsData);
  }

  openSingle = (id) => {
    this.props.history.push("/locations-profile?locationID=" + id);
  };

  openNew = () => {
    this.props.history.push("/locations-profile");
  };

  render() {
    if (this.state.isAuthenticating) return null;

    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text="Loading your content..."
      >
        <div>
          <div className="page-content" id="singles-page">
            <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
              <div className="container container-fluid text-center">
                <Button
                  id="add-user"
                  onClick={this.openNew}
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  style={{
                    float: "right",
                    marginBottom: "15px",
                    marginLeft: "10px",
                  }}
                  endIcon={<Add />}
                >
                  Add Location
                </Button>
                <table
                  className="table table-hover table-striped"
                  cursor="pointer"
                >
                  <thead>
                    <tr>
                      <th scope="col">Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sessionsData.map((row) => (
                      <tr
                        className="clickable-row"
                        onClick={() => this.openSingle(row.id)}
                      >
                        <td>
                          {" "}
                          <a>{row.data.Location}</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
export default withRouter(LocationsPage);
