import React, { useState } from "react";
// import React, { useContext, useState } from "react";
import { auth } from "../firebase";
// import { UserContext } from "../providers/UserProvider";
import { Link } from "@reach/router";
import { TextField, Typography, InputLabel } from "@material-ui/core";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    }
  };

  const sendResetEmail = (event) => {
    event.preventDefault();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setEmailHasBeenSent(true);
        setTimeout(() => {
          setEmailHasBeenSent(false);
        }, 3000);
      })
      .catch(() => {
        setError("Error resetting password");
      });
  };
  return (
    <div className="mt-8">
      <Typography
        variant="h2"
        display="block"
        align="center"
        id="profile-us"
        style={{
          paddingTop: "0",
          paddingBottom: "15px",
          margin: "0rem",
          fontSize: "2.5rem",
        }}
      >
        Reset your Password
      </Typography>
      <div className="border border-blue-300 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
        <form action="">
          {emailHasBeenSent && (
            <div className="py-3 bg-green-400 w-full text-white text-center mb-3">
              An email has been sent to you!
            </div>
          )}
          {error !== null && (
            <div className="py-3 bg-red-600 w-full text-white text-center mb-3">
              {error}
            </div>
          )}
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Email Address:
              </InputLabel>
              <TextField
                type="email"
                id="userEmail"
                name="userEmail"
                variant="outlined"
                onChange={onChangeHandler}
                placeholder="Input your email"
                value={email}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn btn-info"
              style={{ margin: 0, width: "100%" }}
              onClick={(event) => {
                sendResetEmail(event);
              }}
            >
              Send me a reset link
            </button>
          </div>
        </form>

        <Link
          to="/"
          className="my-2 text-blue-700 hover:text-blue-800 text-center block"
        >
          &larr; back to sign in page
        </Link>
      </div>
    </div>
  );
};

export default PasswordReset;
