import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  makeStyles,
  TextField,
  Typography,
  InputLabel,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
// import axios from "axios";
// import Configs from "../editable-stuff/configurations.json";
import firebase from "../firebase";
import { storage } from "../firebase";
import { setDate } from "date-fns";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function LocationsForm() {
  const [fullName, setFullName] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [kid, setKid] = React.useState("");

  const [kidsData, setKidsData] = React.useState([]);
  const [date, setDOB] = React.useState(new Date());

  const db = firebase.firestore();
  const [notes, setNotes] = React.useState("");

  const [sSalary, setSSalary] = React.useState("");
  const [gSalary, setGSalary] = React.useState("");

  const classes = useStyles();
  const history = useHistory();
  var image = null;
  var locationID = "";
  var page = window.location.href;

  if (page.includes("locationID=")) {
    locationID = page.split("=")[1];
  }
  var docId = locationID;
  console.log(locationID + " is the id");

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleGroupSalary = (event) => {
    setGSalary(event.target.value);
  };
  const handleSessionSalary = (event) => {
    setSSalary(event.target.value);
  };

  const initializeForm = () => {
    console.log("Initialize form" + global.config.isSingle);
    var page = window.location.href;
    if (
      page.includes("editable=false") ||
      page.includes("sign-in") ||
      page.includes("signUp")
    ) {
      document.getElementById("save").style.display = "none";
      document.getElementById("delete").style.display = "none";
    }
    var locationID = "";

    if (page.includes("locationID=")) {
      locationID = page.split("=")[1];
    }
    var docRef = db
      .collection("Main")
      .doc(global.config.uid)
      .collection("Locations")
      .doc(locationID);
    debugger;
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());

          document.getElementById("location").value = doc.data().Location;
          document.getElementById("GroupSalary").value = doc.data().GroupSalary;
          document.getElementById("SessionSalary").value =
            doc.data().SessionSalary;

          setLocation(doc.data().Location);
          setSSalary(doc.data().SessionSalary);
          setGSalary(doc.data().GroupSalary);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  useEffect(() => {
    var locationID = "";
    var page = window.location.href;

    if (page.includes("locationID=")) {
      locationID = page.split("=")[1];
    }
    if (locationID !== "") {
      initializeForm();
    }

    return () => {};
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    var locationID = "";
    var page = window.location.href;

    if (page.includes("locationID=")) {
      locationID = page.split("=")[1];
    }

    if (locationID !== "") {
      db.collection("Main")
        .doc(global.config.uid)
        .collection("Locations")
        .doc(locationID)
        .set(
          {
            Location: location,
            GroupSalary: gSalary,
            SessionSalary: sSalary,
          },
          { merge: true }
        )
        .then(() => {
          alert("Document successfully Updated");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    } else {
      db.collection("Main").doc(global.config.uid).collection("Locations").add({
        Location: location,
        GroupSalary: gSalary,
        SessionSalary: sSalary,
      });
      alert("Created Successfully");
    }
  };

  return (
    <div
      id="single"
      className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0"
      width="100%"
    >
      <div className="container container-fluid text-center">
        <Typography
          variant="h2"
          display="block"
          align="center"
          id="single-us"
          style={{
            paddingTop: "0",
            margin: "0rem",
            fontSize: "2.5rem",
          }}
        >
          Locations
        </Typography>
        <form
          autoCorrect="off"
          onSubmit={(e) => handleSubmit(e)}
          id="single-form"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            padding: "15px",
            borderRadius: "10px",
            maxWidth: "1000px",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div className="form-group col-md">
            <InputLabel style={{ textAlign: "left" }}>
              Location <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              required
              type="text"
              onChange={handleLocationChange}
              id="location"
              variant="outlined"
              style={{ marginTop: 0, width: "100%" }}
            />
          </div>
          <div className="form-group col-md">
            <InputLabel style={{ textAlign: "left" }}>
              Session Salary <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              required
              type="text"
              onChange={handleSessionSalary}
              id="SessionSalary"
              variant="outlined"
              style={{ marginTop: 0, width: "100%" }}
            />
          </div>
          <div className="form-group col-md">
            <InputLabel style={{ textAlign: "left" }}>
              Group Salary <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              required
              type="text"
              onChange={handleGroupSalary}
              id="GroupSalary"
              variant="outlined"
              style={{ marginTop: 0, width: "100%" }}
            />
          </div>

          <div className="form-group">
            <Button
              id="save"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.button}
              startIcon={<Save />}
            >
              Save Profile
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

// Countries from https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
