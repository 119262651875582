import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";

import { Save, Delete } from "@material-ui/icons";
// import axios from "axios";
// import Configs from "../editable-stuff/configurations.json";
import firebase from "../firebase";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function CalculatorForm() {
  const [date1, setDate1] = React.useState(new Date());
  const [date2, setDate2] = React.useState(new Date());
  const [sessionsRegularCount, setSessionsRegularCount] = React.useState([]);

  const [kidData, setKidData] = React.useState([]);
  const [sessionData, setSessionData] = React.useState([]);
  const [locationData, setLocationData] = React.useState([]);
  const [isactive, setIsActive] = React.useState(false);

  const classes = useStyles();
  const history = useHistory();
  var page = window.location.href;

  const initializeForm = () => {};

  const handleDateChange = (date) => {
    debugger;
    setDate1(date);
  };

  const handleDateChange2 = (date) => {
    setDate2(date);
  };

  useEffect(() => {
    return () => {};
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(sessionData);

    var kidsDatas = [];
    var sessionDatas = [];
    const db = firebase.firestore();
    await new Promise((resolve, reject) => {
      db.collection("Main")
        .doc(global.config.uid)
        .collection("Kids")
        .onSnapshot((snapshot) => {
          kidsDatas = snapshot.docs.map((doc) => ({
            id: doc.id,
            fullName: doc.data().FirstName + " " + doc.data().LastName,
            location: doc.data().Location,
            data: doc.data(),
          }));

          setKidData(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              fullName: doc.data().FirstName + " " + doc.data().LastName,
              location: doc.data().Location,
              data: doc.data(),
            }))
          );

          resolve("OK");
        });
    });
    await new Promise((resolve, reject) => {
      db.collection("Main")
        .doc(global.config.uid)
        .collection("Sessions")
        .where("Date", ">=", date1)
        .where("Date", "<=", date2)
        .get()
        .then((snapshot) => {
          sessionDatas = snapshot.docs.map((doc) => ({
            id: doc.id,
            fullName: doc.data().fullName,
            kid: doc.data().Kid,
            location: doc.data().Location,
            data: doc.data(),
            sessionType: doc.data().sessionType,
          }));

          setSessionData(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              fullName: doc.data().fullName,
              kid: doc.data().Kid,
              location: doc.data().Location,
              data: doc.data(),
              sessionType: doc.data().sessionType,
            }))
          );

          

          resolve("OK");
        });
    });

    var regularSessionCounts = {};
    var groupSessionCounts = {};
    var regularSessionAmounts = {};
    var groupSessionAmounts = {};
    await new Promise((resolve, reject) => {
      db.collection("Main")
        .doc(global.config.uid)
        .collection("Locations")
        .get()
        .then((snapshot) => {
          debugger;
          snapshot.forEach((doc) => {
            var groupCount = 0;
            var sessionCount = 0;
            var groupSalary = 0;
            var regularSalary = 0;

            var f = sessionDatas.filter(
              (x) =>
                x.location.toLowerCase() === doc.data().Location.toLowerCase()
            );

            for (var i = 0; i < f.length; i++) {
              if (f[i].sessionType === "GroupSession") {
                groupCount++;
                groupSalary += parseFloat(doc.data().GroupSalary);
              } else {
                sessionCount++;
                regularSalary += parseFloat(doc.data().SessionSalary);
              }
            }
            regularSessionCounts[doc.data().Location] = {
              sessionCount: sessionCount,
              groupCount: groupCount,
              regularSalary: regularSalary,
              groupSalary: groupSalary,
            };

            console.log(doc.data().Location);
            console.log(f);
            console.log(sessionCount);

            console.log(groupCount);
            console.log(regularSalary);
            console.log(groupSalary);
            console.log(regularSessionCounts);
          });
          resolve("OK");
          debugger;

          var s =
            " <tr style=\"border:solid;\"><th style=\"border:solid;\">Location</th><th style=\"border:solid;\">Sessions</th> <th style=\"border:solid;\">SessionsSalary</th>  <th style=\"border:solid;\">Groups</th><th style=\"border:solid;\">GroupsSalary</th>  </tr>";
          for (var key in regularSessionCounts) {
            console.log(key, regularSessionCounts[key]);
            s +=
              "<tr style=\"border:solid;\">" +
              "<td style=\"border:solid;\">" +
              key +
              "</td>" +
              "<td style=\"border:solid;\">" +
              regularSessionCounts[key].sessionCount +
              "</td>" +
              "<td style=\"border:solid;\">$" +
              regularSessionCounts[key].regularSalary +
              "</td>" +
              "<td style=\"border:solid;\">" +
              regularSessionCounts[key].groupCount +
              "</td>" +
              "<td style=\"border:solid;\">$" +
              regularSessionCounts[key].groupSalary +
              "</td>" +
              "</tr>";

            document.getElementById("tabledata").innerHTML = s;
            debugger;
            // alert(regularSessionCounts[key].sessionCount)
          }

          setSessionsRegularCount(regularSessionCounts);

          setIsActive(true);
        });
    });
  };

  return (
    <div
      id="single"
      className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0"
      width="100%"
    >
      <div className="page-content" id="Calculator-page">
        <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
          <div className="container container-fluid text-center">
            <form
              autoCorrect="off"
              onSubmit={(e) => handleSubmit(e)}
              id="single-form"
              style={{
                backgroundColor: "transparent",
                flexDirection: "column",
                padding: "15px",
                borderRadius: "10px",
                maxWidth: "1000px",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div className="form-group col-md">
                <InputLabel style={{ textAlign: "left" }}>
                  Date <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="dob1"
                    value={date1}
                    onChange={handleDateChange}
                    style={{ width: "100%" }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div className="form-group col-md">
                <InputLabel style={{ textAlign: "left" }}>
                  Date <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="dob2"
                    value={date2}
                    onChange={handleDateChange2}
                    style={{ width: "100%" }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="form-group">
                <Button
                  id="save"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  className={classes.button}
                  startIcon={<Save />}
                >
                   Check Salary 
                </Button>
              </div>
              <div className="form-group">
                {/* <Button onClick={handleDelete} id="delete" style={{backgroundColor:'red'}} variant="contained" color="primary" size="large"  className={classes.button} startIcon={<Delete />}>Delete Session</Button> */}
              </div>
            </form>

            <table id="tabledata"></table>
          </div>
        </div>
      </div>
    </div>
  );
}

// <SessionsPage kidID ={kidID}/> Countries from https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
