import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Save, Delete } from "@material-ui/icons";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox"; // import axios from "axios";
// import Configs from "../editable-stuff/configurations.json";
import firebase from "../firebase";
import { PDFDocument } from "pdf-lib";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function GroupSessionsForm() {
  const [fullNames, setFullName] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [kid, setKid] = React.useState("");

  const [grouppID, setgroupID] = React.useState("");

  const [kidsData, setKidsData] = React.useState([]);
  const [date, setDOB] = React.useState(new Date());

  const db = firebase.firestore();
  const [notes, setNotes] = React.useState("");
  const [kids, setKids] = React.useState([]);
  const [sessions, setSessions] = React.useState("");

  const [disabled, setDisabled] = React.useState(false);

  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "100%",
      },
    },
  };

  const handleChange = (event) => {
    console.log(kids);
    const {
      target: { value },
    } = event;
    console.log(value);
    setKids(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    console.log(kids);
  };

  const handleDateChange = (date) => {
    setDOB(date);
  };
  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const initializeForm = async () => {
    var page = window.location.href;
    var gid = "";
    if (page.includes("groupID=")) {
      setgroupID(page.split("=")[1]);
      gid = page.split("=")[1];
    }
    console.log("Initialize form" + global.config.isSingle);
    if (
      page.includes("editable=false") ||
      page.includes("sign-in") ||
      page.includes("signUp")
    ) {
      document.getElementById("save").style.display = "none";
      //document.getElementById("delete").style.display="none";
    }
    debugger;
    await new Promise((resolve, reject) => {
      var docRef = db
        .collection("Main")
        .doc(global.config.uid)
        .collection("Groups")
        .doc(gid);
      debugger;
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            debugger;
            setDOB(doc.data().Date.toDate().toDateString());
            debugger;

            setKids(doc.data().Kids);
            setLocation(doc.data().Location);
            document.getElementById("location").value = doc.data().Location;
            setFullName(doc.data().fullNames);
            setSessions(doc.data().sessions);
            var tt = doc.data().sessions.split(",");
            for (var i = 0; i < tt.length; i++) {
              var docRef2 = db
                .collection("Main")
                .doc(global.config.uid)
                .collection("Sessions")
                .doc(tt[i]);
              debugger;
              docRef2
                .get()
                .then((doc2) => {
                  if (doc2.exists) {
                    debugger;
                    document.getElementById("notes" + doc2.data().Kid).value =
                      doc2.data().Notes;
                    resolve("OK");
                  }
                })
                .catch((error) => {
                  debugger;
                  resolve(error);
                  console.log("Error getting document:", error);
                });
            }
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            resolve("Error");
          }
        })
        .catch((error) => {
          resolve(error);
          console.log("Error getting document:", error);
        });
    });
  };

  useEffect(() => {
    var groupID = "";
    var page = window.location.href;

    if (page.includes("groupID=")) {
      groupID = page.split("=")[1];
      setDisabled(true);
    }
    if (groupID !== "") {
      initializeForm();
    }

    const db = firebase.firestore();

    db.collection("Main")
      .doc(global.config.uid)
      .collection("Kids")
      .onSnapshot((snapshot) => {
        setKidsData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            fullName: doc.data().FirstName + " " + doc.data().LastName,
            data: doc.data(),
          }))
        );
      });

    return () => {};
  }, []);
  var cre = false;
  const handleSubmit = async (e) => {
    e.preventDefault();

    var page = window.location.href;

    if (page.includes("groupID=")) {
      setgroupID(page.split("=")[1]);
      setDisabled(true);
    }

    if (grouppID !== "" || cre === true) {
      document.getElementById("save").disabled = true;
      // document.getElementById("delete").disabled = true;
      var t = sessions.split(",");
      for (var i = 0; i < t.length; i++) {
        await new Promise((resolve, reject) => {
          var kidsnotes = document.getElementById(
            "notes" + kids[i].split("_")[0]
          ).value;
          db.collection("Main")
            .doc(global.config.uid)
            .collection("Sessions")
            .doc(t[i])
            .set(
              {
                Notes: kidsnotes,
                Date: date,
                Location: location,
                Kid: kids[i].split("_")[0],
              },
              { merge: true }
            )
            .then((p) => {
              resolve("OK");

              if (t.length - 1 == i) {
                db.collection("Main")
                  .doc(global.config.uid)
                  .collection("Groups")
                  .doc(grouppID)
                  .set(
                    {
                      Kids: kids,
                      Date: date,
                      Location: location,
                      fullNames: fullNames,
                    },
                    { merge: true }
                  )
                  .then(() => {
                    document.getElementById("save").disabled = false;
                    // document.getElementById("delete").disabled = false;
                    alert("Document Successfully Updated");
                  })
                  .catch((error) => {
                    alert("Error writing document: ", error);
                  });
              }
            })
            .catch((error) => {
              resolve(error);
              alert("Error writing document: ", error);
            });
        });
      }
      if (names != "" && names != null) {
        setFullName(names);
      }
    } else {
      setDisabled(true);
      console.log("creating");
      cre = true;
      document.getElementById("save").disabled = true;
      //document.getElementById("delete").disabled = true;
      var names = "";
      var sessionids = "";
      var sessionType = "";
      for (var i = 0; i < kids.length; i++) {
        if (i == 0) {
          sessionType = "MainSession";
        } else {
          sessionType = "GroupSession";
        }
        console.log("h");
        await new Promise((resolve, reject) => {
          if (i == kids.length - 1) names += kids[i].split("_")[1];
          else names += kids[i].split("_")[1] + ", ";

          var kidsnotes = document.getElementById(
            "notes" + kids[i].split("_")[0]
          ).value;
          db.collection("Main")
            .doc(global.config.uid)
            .collection("Sessions")
            .add({
              Kid: kids[i].split("_")[0],
              Date: date,
              Notes: kidsnotes,
              Location: location,
              sessionType: sessionType,
              fullName: kids[i].split("_")[1],
            })
            .then((p) => {
              console.log(p);

              if (i == kids.length - 1) sessionids += p.id;
              else sessionids += p.id + ",";

              if (i == kids.length - 1) {
                db.collection("Main")
                  .doc(global.config.uid)
                  .collection("Groups")
                  .add({
                    Kids: kids,
                    Date: date,
                    Location: location,
                    fullNames: names,
                    sessions: sessionids,
                  })
                  .then((p) => {
                    setSessions(sessionids);
                    console.log(p);
                    setgroupID(p.id);
                    alert("Created Successfully");
                    document.getElementById("save").disabled = false;
                    //document.getElementById("delete").disabled = false;
                    resolve(p.id);
                  })
                  .catch((error) => {
                    resolve(error);
                    alert("Error writing group document: ", error);
                  });
              } else {
                resolve(p.id);
              }
            })
            .catch((error) => {
              resolve(error);
              alert("Error writings123 document: ", error);
            });
        });
      }
    }
  };

  const handleDelete = () => {
    let text;
    if (
      window.confirm("Are you sure you want to delete this record?") === true
    ) {
      db.collection("Main")
        .doc(global.config.uid)
        .collection("Sessions")
        .doc(grouppID)
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          alert("Document successfully deleted!");
        })
        .then(() => {
          window.history.back();
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    } else {
    }
  };
  return (
    <div
      id="single"
      className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0"
      width="100%"
    >
      {/* <form
          autoCorrect="off"
          onSubmit={e => flattenForm(e)}
          id="single-form"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            padding: "15px",
            borderRadius: "10px",
            maxWidth: "1000px",
            display: "inline-block",
            width: "100%",
          }}
        >
     <Button id="downloadpdf" type="submit" ariant="contained" color="primary" size="large"  className={classes.button} >Download PDF</Button>
</form> */}
      <div className="container container-fluid text-center">
        <Typography
          variant="h2"
          display="block"
          align="center"
          id="single-us"
          style={{
            paddingTop: "0",
            margin: "0rem",
            fontSize: "2.5rem",
          }}
        >
          Group Session
        </Typography>
        <form
          autoCorrect="off"
          onSubmit={(e) => handleSubmit(e)}
          id="single-form"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            padding: "15px",
            borderRadius: "10px",
            maxWidth: "1000px",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              disabled={disabled}
              multiple
              value={kids}
              style={{ marginTop: 0, width: "100%" }}
              onChange={handleChange}
              input={<OutlinedInput label="Kids" />}
              renderValue={(selected) => selected.join(",")}
              MenuProps={MenuProps}
            >
              {kidsData.map((option) => (
                <MenuItem
                  key={option.fullName}
                  value={option.id + "_" + option.fullName}
                >
                  <Checkbox
                    checked={
                      kids.indexOf(option.id + "_" + option.fullName) > -1
                    }
                  />
                  <ListItemText primary={option.fullName} />
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="form-group col-md">
            <InputLabel style={{ textAlign: "left" }}>
              Date <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                id="dob"
                value={date}
                onChange={handleDateChange}
                style={{ width: "100%" }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="form-group col-md">
            <InputLabel style={{ textAlign: "left" }}>
              Location <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              required
              type="text"
              onChange={handleLocationChange}
              id="location"
              variant="outlined"
              style={{ marginTop: 0, width: "100%" }}
            />
          </div>

          {kids.map((option) => (
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                {option.split("_")[1]} Notes{" "}
                <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                multiline
                type="text"
                onChange={handleNotesChange}
                id={"notes" + option.split("_")[0]}
                variant="outlined"
                style={{ marginTop: 0, width: "100%" }}
              />
            </div>
          ))}

          <div className="form-group">
            <Button
              id="save"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.button}
              startIcon={<Save />}
            >
              Save Group Session
            </Button>
          </div>
          <div className="form-group">
            {/* <Button onClick={handleDelete} id="delete" style={{backgroundColor:'red'}} variant="contained" color="primary" size="large"  className={classes.button} startIcon={<Delete />}>Delete Session</Button> */}
          </div>
        </form>
      </div>
    </div>
  );
}

// Countries from https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
