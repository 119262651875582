import React, { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
// import { navigate } from "@reach/router";
import { auth } from "../firebase";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
const LoginProfile = (props) => {
  const user = useContext(UserContext);
  const { photoURL, displayName, email } = user;

  global.config.uid = user.uid;
  console.log(user.uid);
  global.config.accountCreated = true;
  document.getElementById("sign-button").innerHTML = "Hello " + displayName;

  return (
    <div className="mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
      <div className="flex border flex-col items-center md:flex-row md:items-start border-blue-400 px-3 py-4">
        <div
          style={{
            background: `url(${
              photoURL ||
              "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"
            })  no-repeat center center`,
            backgroundSize: "cover",
            height: "200px",
            width: "200px",
          }}
          className="border border-blue-300"
        ></div>
        <div className="md:pl-4">
          <h2 className="text-2xl font-semibold">{displayName}</h2>
          <h3 className="italic">{email}</h3>
        </div>
      </div>
      <div className="text-center">
        <button
          className="btn btn-danger"
          style={{ margin: 0, width: "100%" }}
          onClick={() => {
            auth.signOut();
            document.getElementById("sign-button").innerHTML = "Sign In";
          }}
        >
          Sign out
        </button>
      </div>
    </div>
  );
};

export default withRouter(LoginProfile);
