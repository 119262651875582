module.exports = global.config = {
    uid: '',
    singleuid: '',
    accountCreated: false,
    shadchan2:'',
    single1:'',
    single2:'',
    matchesInfoDocID: '',
    userEmail: '',
    isSingle: false,

};