import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Save, Delete } from "@material-ui/icons";
// import axios from "axios";
// import Configs from "../editable-stuff/configurations.json";
import firebase from "../firebase";
import { PDFDocument } from "pdf-lib";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function SessionsForm() {
  const [fullName, setFullName] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [sessionTypes, setSessionTypes] = React.useState([]);
  const [sessionType, setSessionType] = React.useState("");

  const [kid, setKid] = React.useState("");

  const [sessID, setSessionID] = React.useState("");
  const [locationData, setLocationData] = React.useState([]);

  const [kidsData, setKidsData] = React.useState([]);
  const [date, setDOB] = React.useState(new Date());

  const db = firebase.firestore();
  const [notes, setNotes] = React.useState("");

  const classes = useStyles();

  const handleKidChange = (event) => {
    setKid(event.target.value);
    var myElement = document.getElementById(event.target.value);

    // Get the value of the 'name' attribute
    var docRef = db
      .collection("Main")
      .doc(global.config.uid)
      .collection("Kids")
      .doc(event.target.value);
    debugger;
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setFullName(doc.data().FirstName + " " + doc.data().LastName);
          console.log("ddd");
          console.log(doc.data().FirstName + " " + doc.data().LastName);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };
  const handlelocationChange = (event) => {
    setLocation(event.target.value);
  };
  const flattenForm = async (e) => {
    debugger;
    e.preventDefault();
    try {
      const formUrl =
        "https://firebasestorage.googleapis.com/v0/b/otapp-9bd4f.appspot.com/o/oooo.pdf?alt=media&token=5b3b15b9-13f1-45b3-a112-21f15f9319e4";
      const formPdfBytes = await fetch(formUrl, { mode: "cors" }).then((res) =>
        res.arrayBuffer()
      );

      const pdfDoc = await PDFDocument.load(formPdfBytes);

      const form = pdfDoc.getForm();

      form.getTextField("test1").setText("Some Text");
      form.getTextField("test2").setText("i love you");
      //form.getRadioGroup('Group2').select('Choice1');
      //form.getRadioGroup('Group3').select('Choice3');
      //form.getRadioGroup('Group4').select('Choice1');
      //form.getCheckBox('Check Box3').check();

      //form.getDropdown('Dropdown7').select('Infinity');
      //form.getOptionList('List Box6').select('Honda');

      form.flatten();

      const pdfBytes = await pdfDoc.save();
      var bytes = new Uint8Array(pdfBytes); // pass your byte response to this constructor

      var blob = new Blob([bytes], { type: "application/pdf" }); // change resultByte to bytes

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "myFileName.pdf";
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (date) => {
    setDOB(date);
  };
  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleSessionTypeChange = (event) => {
    setSessionType(event.target.value);
    
  };
  const initializeForm = () => {
    var page = window.location.href;
    var sesid = "";
    if (page.includes("sessionID=")) {
      setSessionID(page.split("=")[1]);
      sesid = page.split("=")[1];
    }
    console.log("Initialize form" + global.config.isSingle);
    if (
      page.includes("editable=false") ||
      page.includes("sign-in") ||
      page.includes("signUp")
    ) {
      document.getElementById("save").style.display = "none";
      document.getElementById("delete").style.display = "none";
    }
    var docRef = db
      .collection("Main")
      .doc(global.config.uid)
      .collection("Sessions")
      .doc(sesid);
    debugger;
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          debugger;
          setDOB(doc.data().Date.toDate().toDateString());
          debugger;
          console.log("Document data:", doc.data());

          document.getElementById("notes").value = doc.data().Notes;
          document.getElementById("sessionType").value = doc.data().SessionType;

          document.getElementById("location").value = doc.data().Location;
          //              document.getElementById("kid").value = doc.data().Age;

          setKid(doc.data().Kid);
          setSessionType(doc.data().sessionType)
          setNotes(doc.data().Notes);
          setLocation(doc.data().Location);
          setFullName(doc.data().fullName);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  useEffect(() => {
    var sessionID = "";
    var page = window.location.href;

    if (page.includes("sessionID=")) {
      sessionID = page.split("=")[1];
    }
    if (sessionID !== "") {
      initializeForm();
    }

setSessionTypes(
  [
    "RegularSession",
    "MainSession",
    "GroupSession"
    
  ]
)

    const db = firebase.firestore();

    db.collection("Main")
      .doc(global.config.uid)
      .collection("Kids")
      .onSnapshot((snapshot) => {
        setKidsData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            fullName: doc.data().FirstName + " " + doc.data().LastName,
            data: doc.data(),
          }))
        );
      });

    db.collection("Main")
      .doc(global.config.uid)
      .collection("Locations")
      .onSnapshot((snapshot) => {
        setLocationData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });

    return () => {};
  }, []);
  var cre = false;
  const handleSubmit = (e) => {
    e.preventDefault();

    var page = window.location.href;

    if (page.includes("sessionID=")) {
      setSessionID(page.split("=")[1]);
    }

    if (sessID !== "" || cre === true) {
      document.getElementById("save").disabled = true;
      document.getElementById("delete").disabled = true;

      db.collection("Main")
        .doc(global.config.uid)
        .collection("Sessions")
        .doc(sessID)
        .set(
          {
            Kid: kid,
            sessionType: sessionType,
            Notes: notes,
            Location: location,
            fullName: fullName,
          },
          { merge: true }
        )
        .then(() => {
          document.getElementById("save").disabled = false;
          document.getElementById("delete").disabled = false;

          alert("Document successfully Updated");
        })
        .catch((error) => {
          alert("Error writing document: ", error);
        });
    } else {
      cre = true;
      document.getElementById("save").disabled = true;
      document.getElementById("delete").disabled = true;

      db.collection("Main")
        .doc(global.config.uid)
        .collection("Sessions")
        .add({
          Kid: kid,
          Date: date,
          Notes: notes,
          Location: location,
          fullName: fullName,
          sessionType: "RegularSession",
        })
        .then((p) => {
          console.log(p);
          setSessionID(p.id);
          alert("Created Successfully");
          document.getElementById("save").disabled = false;
          document.getElementById("delete").disabled = false;
        })
        .catch((error) => {
          alert("Error writing document: ", error);
        });
    }
  };

  const handleDelete = () => {
    let text;
    if (
      window.confirm("Are you sure you want to delete this record?") === true
    ) {
      db.collection("Main")
        .doc(global.config.uid)
        .collection("Sessions")
        .doc(sessID)
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          alert("Document successfully deleted!");
        })
        .then(() => {
          window.history.back();
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    } else {
    }
  };
  return (
    <div
      id="single"
      className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0"
      width="100%"
    >
      {/* <form
          autoCorrect="off"
          onSubmit={e => flattenForm(e)}
          id="single-form"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            padding: "15px",
            borderRadius: "10px",
            maxWidth: "1000px",
            display: "inline-block",
            width: "100%",
          }}
        >
     <Button id="downloadpdf" type="submit" ariant="contained" color="primary" size="large"  className={classes.button} >Download PDF</Button>
</form> */}
      <div className="container container-fluid text-center">
        <Typography
          variant="h2"
          display="block"
          align="center"
          id="single-us"
          style={{
            paddingTop: "0",
            margin: "0rem",
            fontSize: "2.5rem",
          }}
        >
          Session
        </Typography>
        <form
          autoCorrect="off"
          onSubmit={(e) => handleSubmit(e)}
          id="single-form"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            padding: "15px",
            borderRadius: "10px",
            maxWidth: "1000px",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div className="form-row">
            <TextField
              id="kidsnames"
              select
              label="Please select a child"
              required
              variant="outlined"
              value={kid}
              style={{ marginTop: 0, width: "100%" }}
              onChange={handleKidChange}
            >
              {kidsData.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  id={option.id}
                  name={option.fullName}
                >
                  {option.fullName}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="form-group col-md">
            <InputLabel style={{ textAlign: "left" }}>
              Date <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                id="dob"
                value={date}
                onChange={handleDateChange}
                style={{ width: "100%" }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="form-group col-md">
          <TextField
            id="location"
            select
            label="Please select a Location"
            required
          
            variant="outlined"
            value={location}
            style={{ marginTop: 0, width: "100%" }}
            onChange={handlelocationChange}
          >
            {locationData.map((option) => (
              <MenuItem
                key={option.id}
                value={option.data.Location}
                id={option.id}
                name={option.data.Location}
              >
                {option.data.Location}
              </MenuItem>
            ))}
          </TextField>
          </div>
          <div className="form-group col-md"> 
          <TextField
            id="sessionType"
            select
            label="Please select a Session Type"
            required
            variant="outlined"
            value={sessionType}
            style={{ marginTop: 0, width: "100%" }}
            onChange={handleSessionTypeChange}
          >
            {sessionTypes.map((option) => (
              <MenuItem
                key={option}
                value={option}
              
              >
                {option}
              </MenuItem>
            ))}
          </TextField>
          </div>
          
          <div className="form-group col-md">
            <InputLabel style={{ textAlign: "left" }}>
              Notes <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              required
              multiline
              type="text"
              onChange={handleNotesChange}
              id="notes"
              variant="outlined"
              style={{ marginTop: 0, width: "100%" }}
            />
          </div>

          <div className="form-group">
            <Button
              id="save"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.button}
              startIcon={<Save />}
            >
              Save Session
            </Button>
          </div>
          <div className="form-group">
            <Button
              onClick={handleDelete}
              id="delete"
              style={{ backgroundColor: "red" }}
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<Delete />}
            >
              Delete Session
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

// Countries from https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
