import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";

import { Save, Delete } from "@material-ui/icons";
// import axios from "axios";
// import Configs from "../editable-stuff/configurations.json";
import firebase from "../firebase";

var firstNameSelected;
var lastNameSelected;
var ageSelected;
var locationSelected;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function KidsForm() {
  const [firstName, setFirstName] = React.useState("");
  const [evaluation, setEval] = React.useState("");
  const [location, setLocation] = React.useState("");

  const [kidID, setKidID] = React.useState("");
  const db = firebase.firestore();
  const [age, setAge] = React.useState("");

  const [lastName, setLastName] = React.useState("");
  const [locationData, setLocationData] = React.useState([]);

  const classes = useStyles();
  const history = useHistory();
  var page = window.location.href;

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    firstNameSelected = event.target.value;
  };

  const handleEvaluationChange = (event) => {
    setEval(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    lastNameSelected = event.target.value;
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value);
    ageSelected = event.target.value;
  };

  const handlelocationChange = (event) => {
    setLocation(event.target.value);
    locationSelected = event.target.value;
  };

  const initializeForm = () => {
    var page = window.location.href;
    debugger;
    var origkidid = "";
    if (page.includes("kidID=")) {
      console.log(page.split("=")[1]);
      setKidID(page.split("=")[1]);
      origkidid = page.split("=")[1];

      console.log("Initialize form" + global.config.isSingle);
      if (
        page.includes("editable=false") ||
        page.includes("sign-in") ||
        page.includes("signUp")
      ) {
        document.getElementById("save").style.display = "none";
        document.getElementById("delete").style.display = "none";
      }

      var docRef = db
        .collection("Main")
        .doc(global.config.uid)
        .collection("Kids")
        .doc(origkidid);
      debugger;
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            debugger;
            console.log("Document data:", doc.data());

            //window.alert(response.data.firstName);
            document.getElementById("firstname").value = doc.data().FirstName;
            document.getElementById("lastname").value = doc.data().LastName;
            document.getElementById("age").value = doc.data().Age;
            document.getElementById("location").value = doc.data().Location;
            document.getElementById("eval").value = doc.data().Eval;

            setFirstName(doc.data().FirstName);
            setLastName(doc.data().LastName);
            setAge(doc.data().Age);
            setLocation(doc.data().Location);
            setEval(doc.data().Eval);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  };

  useEffect(() => {
    initializeForm();

    const db = firebase.firestore();

    db.collection("Main")
      .doc(global.config.uid)
      .collection("Locations")
      .onSnapshot((snapshot) => {
        setLocationData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });

    return () => {};
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    var page = window.location.href;

    if (page.includes("kidID=")) {
      console.log(page.split("=")[1]);
      setKidID(page.split("=")[1]);
    }

    const firstName = document.getElementById("firstname").value;
    const lastName = document.getElementById("lastname").value;
    const age = document.getElementById("age").value;
    const evalu = document.getElementById("eval").value;

    if (kidID !== "") {
      db.collection("Main")
        .doc(global.config.uid)
        .collection("Kids")
        .doc(kidID)
        .set({
          FirstName: firstName,
          LastName: lastName,
          Age: age,
          Location: location,
          Eval: evalu,
        })
        .then(() => {
          alert("Document successfully Updated");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    } else {
      db.collection("Main")
        .doc(global.config.uid)
        .collection("Kids")
        .add({
          FirstName: firstName,
          LastName: lastName,
          Age: age,
          Location: location,
          Eval: evalu,
        })
        .then((p) => {
          setKidID(p.id);
          alert("Created Successfully");
        });
    }
  };

  const handleDelete = () => {
    let text;
    if (
      window.confirm("Are you sure you want to delete this record?") === true
    ) {
      db.collection("Main")
        .doc(global.config.uid)
        .collection("Kids")
        .doc(kidID)
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          alert("Document successfully deleted!");
        })
        .then(() => {
          window.history.back();
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    } else {
    }
  };
  return (
    <div
      id="single"
      className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0"
      width="100%"
    >
      <div className="container container-fluid text-center">
        <Typography
          variant="h2"
          display="block"
          align="center"
          id="single-us"
          style={{
            paddingTop: "0",
            margin: "0rem",
            fontSize: "2.5rem",
          }}
        >
          Kid
        </Typography>
        <form
          autoCorrect="off"
          onSubmit={(e) => handleSubmit(e)}
          id="single-form"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            padding: "15px",
            borderRadius: "10px",
            maxWidth: "1000px",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                First Name <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                variant="outlined"
                id="firstname"
                style={{ marginTop: 0, width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Last Name <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                id="lastname"
                variant="outlined"
                style={{ marginTop: 0, width: "100%" }}
              />
            </div>
          </div>
          <div className="form-group col-md">
            <InputLabel style={{ textAlign: "left" }}>
              Age <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              required
              type="text"
              variant="outlined"
              id="age"
              style={{ marginTop: 0, width: "100%" }}
            />
          </div>

          <TextField
            id="location"
            select
            label="Select"
            required
            helperText="Please select a Location"
            variant="outlined"
            value={location}
            style={{ marginTop: 0, width: "100%" }}
            onChange={handlelocationChange}
          >
            {locationData.map((option) => (
              <MenuItem
                key={option.id}
                value={option.data.Location}
                id={option.id}
                name={option.data.Location}
              >
                {option.data.Location}
              </MenuItem>
            ))}
          </TextField>
          <div className="form-group col-md">
            <InputLabel style={{ textAlign: "left" }}>
              Evaluation and concerns <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              required
              multiline
              type="text"
              id="eval"
              variant="outlined"
              style={{ marginTop: 0, width: "100%" }}
            />
          </div>

          <div className="form-group">
            <Button
              id="save"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.button}
              startIcon={<Save />}
            >
              Save Kid
            </Button>
          </div>
          <div className="form-group">
            <Button
              onClick={handleDelete}
              id="delete"
              style={{ backgroundColor: "red" }}
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<Delete />}
            >
              Delete Kid
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

// <SessionsPage kidID ={kidID}/> Countries from https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
