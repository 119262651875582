import React, { useContext } from "react";
import { Router } from "@reach/router";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
// import UserProvider from "../providers/UserProvider";
import LoginProfile from "./LoginProfile";
import { UserContext } from "../providers/UserProvider";
import PasswordReset from "./PasswordReset";
import "../App";
function Application() {
  const user = useContext(UserContext);
  console.log("made");
  return user ? (
    <LoginProfile />
  ) : (
    <Router>
      <SignIn path="/" />
      <SignUp path="/signUp" />
      <PasswordReset path="passwordReset" />
    </Router>
  );
}

export default Application;
