import React from "react";
import { Link } from "react-router-dom";
import Typist from "react-typist";
import Jpg from "../editable-stuff/home_logo.png";
import "../App";

const MainPage = () => {
  return (
    <div>
      <div
        id="mainHeader"
        className="jumbotron jumbotron-fluid bg-light text-light min-vh-auto d-flex align-content-center align-items-center flex-wrap"
        style={{ marginBottom: 0 }}
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <img src={Jpg} className="img-fluid" alt="Responsive"></img>
        </div>
        <div
          style={{ marginTop: "25px" }}
          className="container container-fluid text-center"
        >
          <h3 className="display-3 text-dark">OT App</h3>
          <Typist cursor={{ hideWhenDone: true }} className="lead text-dark">
            Automation for OT.
          </Typist>
          <br></br>
          <Link className="btn btn-outline-dark" role="button" to="/kids">
            Kids
          </Link>
          <Link className="btn btn-outline-dark" role="button" to="/sessions">
            Sessions
          </Link>
          <Link className="btn btn-outline-dark" role="button" to="/locations">
            Locations
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
