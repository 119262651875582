import React from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import Jpg from "../editable-stuff/404.jpg";
import "../App";

const NotFoundPage = () => {
  return (
    <div>
      <div
        id="404"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-dark d-flex align-content-center align-items-center"
      >
        <div className="container container-fluid text-center ">
          <div style={{display: "flex", justifyContent:"center", width:"100%"}}>
          <img src={Jpg} className="img-fluid" alt="Responsive"></img>
          </div>
          <h1 className="display-1" style={{color: "grey", marginTop: "30px", marginBottom: "30px"}}>404: Page Not Found</h1>
          <Typist cursor={{ hideWhenDone: true }}>
            <span className="lead" style={{color: "grey"}}>Sorry the page you were looking for no longer exists. Please check out one of our other pages!</span>
          </Typist>
          <br></br>
          <Link className="btn btn-outline-dark" role="button" to="/">Home</Link>
          <Link className="btn btn-outline-dark" role="button" to="/singles">Singles</Link>
          <Link className="btn btn-outline-dark" role="button" to="/matches">Matches</Link>
          <Link className="btn btn-outline-dark" role="button" to="/profile">My Profile</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;