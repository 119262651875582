import React from "react";
import { Add } from "@material-ui/icons";
import { Button, TextField, MenuItem } from "@material-ui/core";

import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
/* eslint-disable */
import firebase from "../firebase";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

class SessionsPage extends React.Component {
  //history = useHistory();

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      sessionsData: new Map(),
      isActive: true,
      isAuthenticating: true,
      fff: "sss",
      kid: "",
      fullName: "",
      kidsData: new Map(),
      columns: [],
    };
  }

  componentWillMount() {
    const cols = [
      { field: "id", headerName: "ID", width: 90 },
      
      {
        field: "fullName",
        headerName: "Full Name",
        width: 150,
      },
      {
        field: "location",
        headerName: "Location",
        width: 150,
      },
      {
        field: "date",
        headerName: "Date",
        width: 150,
      },
      {
        field: "sessionType",
        headerName: "Type",
        width: 150,
      },
      {
        field: "notes",
        headerName: "Notes",
        width: "100%",
      },
     
    ];
    this.setState({ columns: cols });

    console.log("ddd");
    console.log(this.props.kidID);
    var url_string = window.location.href;
    if (global.config.uid === "") {
      window.alert("You are not signed in");
      window.location.replace("/");
    } else {
      var there = this;
      const db = firebase.firestore();

      new Promise(function () {
        db.collection("Main")
          .doc(global.config.uid)
          .collection("Sessions")
          .orderBy("Date", "desc")
          .onSnapshot((snapshot) => {
            there.setState({
              sessionsData: snapshot.docs.map((doc) => ({
                id: doc.id,
                fullName: doc.data().fullName,
                date: doc.data().Date.toDate().toDateString(),
                kid: doc.data().Kid,
                location: doc.data().Location,
                notes: doc.data().Notes,
                data: doc.data(),
                sessionType: doc.data().sessionType,
              })),
            });

            debugger;
            db.collection("Main")
              .doc(global.config.uid)
              .collection("Kids")
              .orderBy("LastName")
              .onSnapshot((snapshot) => {
                there.setState({
                  kidsData: snapshot.docs.map((doc) => ({
                    id: doc.id,
                    fullName: doc.data().FirstName + " " + doc.data().LastName,
                    data: doc.data(),
                  })),
                });

                there.setState({ fff: "sdfr" });

                there.setState({ isAuthenticating: false });
                there.setState({ isActive: false });
              });
          });
      });
    }
  }
  componentDidMount() {
    console.log(this.state.sessionsData);
  }

  openSingle = (parameters) => {
    this.props.history.push("/sessions-profile?sessionID=" + parameters.id);
  };

  openNew = () => {
    this.props.history.push("/sessions-profile");
  };
  openNewGroupSession = () => {
    this.props.history.push("/group-profile");
  };

  handleKidChange = (event) => {
    const db = firebase.firestore();
console.log('sssss' + event.target.value)
    this.setState({ isAuthenticating: true });
    this.setState({ isActive: true });
    this.setState({ kid: event.target.value });

    var myElement = document.getElementById(event.target.value);

    // Get the value of the 'name' attribute
    var nameAttributeValue = myElement.getAttribute("name");
    this.setState({ fullName: nameAttributeValue });

    db.collection("Main")
      .doc(global.config.uid)
      .collection("Sessions")
      .where("Kid", "==", event.target.value)
      .onSnapshot((snapshot) => {
        this.setState({
          sessionsData: snapshot.docs.map((doc) => ({
            id: doc.id,
            fullName: doc.data().fullName,
            date: doc.data().Date.toDate().toDateString(),
            kid: doc.data().Kid,
            location: doc.data().Location,
            notes: doc.data().Notes,
            data: doc.data(),
          })),
        });

        this.setState({ isAuthenticating: false });
        this.setState({ isActive: false });
      });
  };

  render() {
    if (this.state.isAuthenticating) return null;

    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text="Loading your content..."
      >
        <div>
          <div className="page-content" id="singles-page">
            <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
              <div className="container container-fluid text-center">
                <div>
                  <div>
                    <Button
                      id="add-group-user"
                      onClick={this.openNew}
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      style={{
                        float: "right",
                        marginBottom: "15px",
                        marginLeft: "10px",
                      }}
                      endIcon={<Add />}
                    >
                      Add Session
                    </Button>
                  </div>

                  <div>
                    <Button
                      id="add-user"
                      onClick={this.openNewGroupSession}
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      style={{
                        float: "right",
                        marginBottom: "15px",
                        marginLeft: "10px",
                      }}
                      endIcon={<Add />}
                    >
                      Add Group Session
                    </Button>
                  </div>
                </div>
                <TextField
                  id="kidsnames"
                  select
                  label="Select"
                  required
                  helperText="Please select a child"
                  variant="outlined"
                  value={this.state.kid}
                  onChange={this.handleKidChange}
                  style={{ marginTop: 0, width: "100%" }}
                >
                  {this.state.kidsData.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      id={option.id}
                      name={option.fullName}
                    >
                      {option.fullName}
                    </MenuItem>
                  ))}
                </TextField>

                {/* <table className="table table-hover table-striped" cursor="pointer">
                <thead>
                  <tr>
                    <th scope="col">Kid</th>
                    <th scope="col">Location</th>
                    <th scope="col">Date</th>
                    <th scope="col">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sessionsData.map(row=> (
                    <tr className='clickable-row' onClick={() => this.openSingle(row.id)} >
                      <td><a>{row.data.fullName}</a></td>
                      <td> <a>{row.data.Location}</a></td>
                      <td> <a>{row.data.Date.toDate().toDateString()}</a></td>
                      <td> <a>{row.data.Notes}</a></td>
                    </tr>
                  ))}
                </tbody>
              </table>
               */}

                <br />
                <br />
                <br />

                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    ".MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "bold !important",
                      overflow: "visible !important",
                      fontSize: "Larger",
                    },
                    ".MuiDataGrid-virtualScroller": {
                      overflow: "visible !important",
                      fontSize: "Large",
                    },
                  }}
                >
                  <DataGrid
                    getRowHeight={() => "auto"}
                    onCellClick={this.openSingle}
                    rows={this.state.sessionsData}
                    columns={this.state.columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 15,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
export default withRouter(SessionsPage);
