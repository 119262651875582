import React from 'react';
import { Link } from 'react-router-dom';
// import { Link, useHistory } from 'react-router-dom';
import './Navigation.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
/* eslint-disable */

export default function SinglesNavigation() {
  // const history = useHistory();

//  const scrollFunc=()=>{
//   var url = window.location.href;
//      if (url.includes('singles')){
//        document.getElementById("singlesNav").className = "nav-item active";
//        document.getElementById("matchesNav").className = "nav-item";
//        document.getElementById("profileNav").className = "nav-item";
//      }
//      else if (url.includes('matches')){
//        document.getElementById("singlesNav").className = "nav-item";
//        document.getElementById("matchesNav").className = "nav-item active";
//        document.getElementById("profileNav").className = "nav-item";
//      }
//      else if (url.includes('profile')){
//        document.getElementById("singlesNav").className = "nav-item";
//        document.getElementById("matchesNav").className = "nav-item";
//        document.getElementById("profileNav").className = "nav-item active";
//      }
//    }

//    window.onload = scrollFunc;
  var link = window.location.href;
  if(link.includes("single-sign-up"))
  {
    return null;
  }
  else if (global.config.isSingle)
  {
    return null;
  }
  else
  {
  
  return (
    <div id = "navigationBar" style={{paddingBottom: 50}}>
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark" id="singlesNavbar" style={{paddingBottom: 0, paddingTop:0}}>
        <Link to = "/" id="homeNav" className="navbar-brand" style={{cursor:"pointer"}} >
        <img src={require('./heart.png')} width="30" height="30" className="d-inline-block align-top" alt=""/>
                  OT App
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#singles-navbar" aria-controls="singles-navbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="singles-navbar">
          <ul className="navbar-nav mr-auto" data-toggle="collapse" data-target="#singles-navbar">
            <li className="nav-item" id="singlesNav">
              <Link className="nav-link" role="button"  to="/kids">Kids</Link>
            </li>
            <li className="nav-item" id="singlesNav">
              <Link className="nav-link" role="button"  to="/sessions">Sessions</Link>
            </li>
            <li className="nav-item" id="singlesNav">
              <Link className="nav-link" role="button"  to="/groups">Groups</Link>
            </li>
            <li className="nav-item" id="singlesNav">
              <Link className="nav-link" role="button"  to="/locations">Locations</Link>
            </li>
            <li className="nav-item" id="singlesNav">
              <Link className="nav-link" role="button"  to="/Calculator">Calculator</Link>
            </li>
           
          </ul>
          <ul className="form-inline my-2 my-lg-0">
            <Link className="btn btn-outline-light" id="sign-button" role="button" to="/sign-in">Sign In</Link>
          </ul>
        </div>      		
      </nav>
    </div>
  );
  }

  
}