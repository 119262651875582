import React from "react";
import SessionsForm from "../Components/SessionsForm";
import profilePicture from "../editable-stuff/profile.jpg";
import { storage } from "../firebase";
import { PhotoCamera } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";

var FirstName = "First";
var LastName = "Last";
var Description =
  "Your description will go here. You can write up to 500 characters.";
var LastUpdated = "Last updated 10/01/2020 at 4:56PM.";

var url;

class SessionsProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: null,
      isActive: false,
    };
  }
  componentDidMount() {}
  componentWillMount() {}
  componentWillUnmount() {}
  render() {
    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        text="Loading your content..."
      >
        <div>
          <div className="page-content" id="singles-profile">
            <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
              <div className="container container-fluid text-center">
                <SessionsForm />
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
export default SessionsProfile;
